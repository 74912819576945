<template>
  <div class="recharge-top">
    <div class="logo" />
    <h3 class="slogan">
      {{ $t('subscribe.slogan') }}
    </h3>
    <ul class="introduce">
      <li>
        <div class="success" />
        <span>{{ $t('subscribe.introduce_1') }}</span>
      </li>
      <li>
        <div class="success" />
        <span>{{ $t('subscribe.introduce_2') }}</span>
      </li>
      <li>
        <div class="success" />
        <span>{{ $t('subscribe.introduce_3') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RechargeTop'
}
</script>

<style scoped lang="less">
  .recharge-top{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo{
    width: 215px;
    height: 192px;
    background: url("~@/assets/img/app/huiyuan-4@2x.png")  100% center/ 100%;
    margin: 80px 0;
  }
  h3.slogan{
    font-size: 44px;
    line-height: 52px;
    font-weight: 500;
    color: #222222;
  }
  ul.introduce{
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    li{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      div.success{
        display: inline-block;
        width: 28px;
        height: 28px;
        background: url("~@/assets/img/app/xuanzhong@2x(1).png") 100% center/ 100%;
        margin-right: 10px;
      }
      >span{
        font-size: 30px;
        line-height: 36px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
</style>

<template>
  <div class="RechargePayment">
    <ul class="set-menu">
      <li
        v-for="(item, index) in subscriptionProductList"
        :key="index"
        :class="{
          active: activeIndex === index
        }"
        @click="activeIndex = index"
      >
        <div class="encourage">
          {{ $t(`subscribe.encourage_discounts`, {discounts: item.discount}) }}
        </div>
        <!-- <template v-if="item.discounts">
          <div class="encourage">
            {{ $t(`subscribe.encourage_discounts`, {discounts: item.discounts}) }}
          </div>
        </template>
        <template v-else>
          <div class="encourage">
            {{ $t('subscribe.encourage_only', { money: item.money}) }}
          </div>
        </template> -->
        <div class="months">
          {{ item.timeNumber }}
        </div>
        <div class="months-unit">
          {{ $t('subscribe.monthUnit') }}
        </div>
        <div class="price">
          ${{ item.price }}
        </div>
        <div class="price-months">
          ${{ (item.price / item.timeNumber).toFixed(2) }}/mon
        </div>
      </li>
    </ul>
    <div
      ref="paypal"
      class="pay-button"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { createOrder } from '@/api/pay'

import {
  subscriptionFailed
} from '@/utils'

import { logEvent } from '@/firebase/Analytics.js'
let paypalButtons
let paypal
const envMap = { local: 'dev', dev: 'dev', pre: 'pre', prod: 'pro' }

const VUE_APP_PAYPAL_CLIENTID = process.env.VUE_APP_PAYPAL_CLIENTID

export default {
  name: 'RechargePayment',
  metaInfo: {
    script: [
      {
        src: `https://www.paypal.com/sdk/js?client-id=${VUE_APP_PAYPAL_CLIENTID}&components=buttons&vault=true&intent=subscription`,
        'data-sdk-integration-source': 'button-factory'
      }
    ]
  },
  data () {
    return {
      loading: false,
      activeIndex: 0,
      orderId: ''
    }
  },
  computed: {
    ...mapState('pay', ['subscriptionProductList'])
  },
  created () {
    this.getSubscriptionProductList()
  },
  mounted () {
    setTimeout(() => {
      paypal = window.paypal
      this.createOrder()
      this.updateButtons()
    }, 500)
  },
  methods: {
    ...mapActions('pay', ['getSubscriptionProductList']),
    createOrder () {
      this.$toast.allowMultiple(true)
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        allowMultiple: true
      })
      createOrder({ channel: 3 })
        .then(res => {
          if (res.code === 200) {
            this.orderId = res.data.orderIdStr
          } else {
            this.$toast.fail({
              message: res.msg
            })
          }
        })
        .finally(() => {
          toast.clear()
        })
    },
    updateButtons () {
      paypalButtons = paypal.Buttons({
        style: {
          layout: 'vertical',
          color: 'gold',
          shape: 'rect',
          label: 'paypal'
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: this.subscriptionProductList[this.activeIndex].subScriptionId,
            custom_id: JSON.stringify({
              orderId: this.orderId,
              env: envMap[process.env.VUE_APP_MODE],
              planId: this.subscriptionProductList[this.activeIndex].subScriptionId
            })
          })
        },
        onApprove: (data, actions) => {
          logEvent('result_subscription', { message: 'success', subId: this.activeIndex, ...data, orderId: this.orderId })
          this.$router.push({
            path: '/paypal/waitting',
            query: {
              payload: JSON.stringify({ ...data, orderId: this.orderId })
            }
          })
        },
        onError: (data) => {
          logEvent('result_subscription', { message: 'failed', ...data, orderId: this.orderId })
          subscriptionFailed(data)
        }
      })
      paypalButtons.render(this.$refs.paypal)
    }
  }
}
</script>

<style scoped lang="less">
  .RechargePayment{
    width: 100%;
    ul.set-menu{
      display: flex;
      list-style: none;
      justify-content: space-between;
      margin-block-start: 70px;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      padding: 0 42px;
      box-sizing: border-box;
      >li{
        @border-w: 4px;
        width: 202px;
        height: 261px;
        box-sizing: border-box;
        background: #F4F4F4;
        border-radius: 9px;
        border: @border-w solid transparent;
        position: relative;
        text-align: center;
        .encourage{
          display: none;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 0;
          left: 50%;
          height: 53px;
          line-height: 53px;
          padding: 0 8px;
          background: #FFD340;
          border-radius: 7px;
          font-size: 21px;
          color: #ffffff;
          white-space: nowrap;
        }
        .months{
          font-size: 40px;
          line-height: 48px;
          margin: 42px 0 10px;
          color: #000000;
        }
        .months-unit{
          font-size: 23px;
          line-height: 28px;
          color: #000000;
        }
        .price{
          font-size: 25px;
          line-height: 31px;
          color: #333333;
          margin: 28px 0 10px;
        }
        .price-months{
          font-size: 19px;
          line-height: 23px;
          color: #999999;
          transform: scale(0.78);
        }
        &.active{
          border: @border-w solid #FFD340;
          background: #FFFFFF;
          box-shadow: 0 5px 11px rgba(255, 210, 63, 0.5);
          .encourage{
            display: block;
          }
          .months,
          .months-unit{
            color: #FFD340;
          }
        }
      }
    }
    .pay-button{
      width: 684px;
      margin: 46px auto 0;
    }
  }
</style>

<template>
  <div class="RechargeAgreement">
    <!--    <a-->
    <!--      href="###"-->
    <!--      @click.prevent="handleManageSubscription"-->
    <!--    >Manage subscription</a>-->
    <div class="introduce-wrapper">
      <div class="introduce">
        <p>{{ $t('subscribe.agreementInstructionMain') }}</p>
        <p>{{ $t('subscribe.agreementInstructionTitle') }}</p>
        <p>{{ $t('subscribe.agreementInstructionItem_1') }}</p>
        <p>{{ $t('subscribe.agreementInstructionItem_2') }}</p>
        <p>{{ $t('subscribe.agreementInstructionItem_3') }}</p>
      </div>
    </div>
    <a
      href="###"
      @click.prevent="handleViewAgreement"
    >VIP Service Agreement</a>
  </div>
</template>

<script>

export default {
  name: 'RechargeAgreement',
  methods: {
    handleManageSubscription () {
    },
    handleViewAgreement () {
      this.$router.push({
        path: '/netboom/VipAgreements'
      })
    }
  }
}
</script>

<style scoped lang="less">
.RechargeAgreement{
  text-align: center;
  padding-bottom: 20px;
  a{
    color: #007FFF;
    font-size: 28px;
    line-height: 32px;
  }
  .introduce-wrapper{
    height: 200px;
  }
  .introduce{
    width: 150%;
    margin: 40px 0;
    padding: 0 30px;
    transform-origin: 0 0;
    transform: scale(0.62);
    p{
      font-size: 15px;
      color: #999999;
      display: block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-bottom: 16px;
      &:nth-of-type(n + 3) {
        margin-bottom: 0;
        line-height: 1.4;
      }
    }
  }
}
</style>

<template>
  <div class="app">
    <!--    LOGO SLOGAN DESCRIPTION-->
    <RechargeTop />
    <!-- 支付模块 -->
    <RechargePayment />
    <!--    管理订阅 & 用户协议-->
    <RechargeAgreement />
  </div>
</template>

<script>
import RechargeTop from '@/views-rem/paypal_subscribe/components/RechargeTop'
import RechargePayment from '@/views-rem/paypal_subscribe/components/RechargePayment'
import RechargeAgreement from '@/views-rem/paypal_subscribe/components/RechargeAgreement'

import { mapMutations } from 'vuex'
export default {
  name: 'PaypalSubscribe',
  components: {
    RechargeTop,
    RechargePayment,
    RechargeAgreement
  },
  created () {
    const token = this.$route.query.token
    this.SET_TOKEN(token)
  },
  methods: {
    ...mapMutations('service', ['SET_TOKEN'])
  }
}
</script>

<style scoped lang="less">
  .app{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto, Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
  }
</style>
